html, body{
  @include font;
  font-size: $fontS;
  line-height: 1.2;
  letter-spacing: 0.02em;
  color: $black;
  background-color: $light-grey;
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

body{
  width: 100%;
  overflow-x: hidden;
  height: 100%;
}

.page {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.section {
  font-size: $fontS;
  padding-top: 70px;
  max-width: 1000px;
  width: 1000px;
  margin: auto;
  position: relative;
}

.content {
  flex: 1 0 auto;
}

.footer {
  flex-shrink: 0;
}

@media (max-width: $screen-md-min) {
  .section {
    padding-top: 110px;
    max-width: auto;
    width: 100%;
    margin: auto;
    position: relative;
  }

  .container {
    padding-bottom: 40px;
  }
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: $dark-grey;

  &:hover {
    background-color: $middle-grey;
  }
}
