// POSITIONNING
.float-right {
  float: right;
}

.float-left {
  float: left;
}

.clearfix {
  &:before,
  &:after {
    content: "";
    display: table;
  }
  &:after {
    clear: both;
  }
}

/* --- ALIGNEMENT --- */
.text_center, .text-center {
  text-align: center;
}

.text_left, .text-left {
  text-align: left;
}

.text_right, .text-right {
  text-align: right;
}

.text_justify, .text-justify {
  text-align: justify;
}

.flex {
  display: flex;
  justify-content: space-between;
}

.fill-flex-space {
  flex: 1 1 auto;
}

.vertical-align {
  display: flex;
  align-items: center;
}

.align-bottom {
  vertical-align: bottom;
}

.align-top {
  vertical-align: top;
}

.align-middle {
  vertical-align: middle;
}

.no-padding, .nopadding, .no-gutters {
  padding:0 !important;
}

.no-margin {
  margin: 0 !important;
}

.clear {
  clear: both;
}

.capitalize {
  text-transform: capitalize;
}

/* --- WIDTH --- */
.full_width, .full-width {
  width: 100%;
  display: block;
}

/* --- VISIBILITY --- */
.hidden {
  display: none;
}

.invisible {
  visibility: hidden;
  opacity: 0;
}

/* --- MARGINS / PADDINGS --- */
$sizes: 0, 5, 10, 20, 30, 40, 50, 60;
@each $size in $sizes {
  .p#{$size} {
    padding: #{$size}px !important;
  }

  .pt#{$size} {
    padding-top: #{$size}px !important;
  }

  .pr#{$size} {
    padding-right: #{$size}px !important;
  }

  .pb#{$size} {
    padding-bottom: #{$size}px !important;
  }

  .pl#{$size} {
    padding-left: #{$size}px !important;
  }

  .m#{$size} {
    margin: #{$size}px !important;
  }

  .mt#{$size} {
    margin-top: #{$size}px !important;
  }

  .mr#{$size} {
    margin-right: #{$size}px !important;
  }

  .mb#{$size} {
    margin-bottom: #{$size}px !important;
  }

  .ml#{$size} {
    margin-left: #{$size}px !important;
  }
}

.display-flex-center {
  display: flex;
  align-items: center;
}
