/* --- COLORS --- */
$primary: #25864F;
$accent: #D38029;
$black: #333333;
$light-grey: #f2f2f2;
$middle-grey: #a7a7a7;
$dark-grey: #686868;
$white: #ffffff;
$red: #c51609;
$orange: #FF8F00;
$green: #6a9d34;
$accentrgb: 211,128,41;
