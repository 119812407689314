.print-only {
  display: none;
}

@media print {
  .print-only {
    display: block;
  }

  .do-not-print {
    display: none;
  }
}
