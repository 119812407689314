html,body,div,span,object,iframe,h1,h2,h3,h4,h5,h6,p,blockquote,pre,a,abbr,address,cite,code,del,dfn,em,img,ins,q,small,strong,sub,sup,dl,dt,dd,ol,ul,li,fieldset,form,label,legend,table,caption,tbody,tfoot,thead,tr,th,td{border:0;margin:0;padding:0}
article,aside,figure,figure img,figcaption,hgroup,footer,header,nav,section,video,object{display:block}
a img{border:0}
ul li{
  list-style-type:none;
}

*{
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

a{
  background: transparent;
  // COULEUR DU CLIC SUR MOBILE
  -webkit-tap-highlight-color: $black;

  &:active,
  .no_touch &:hover,
  &:focus{
    outline: 0;
  }
}


*,
*:before,
*:after {
  outline: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}


// RESET FORMS
button,
input {
  line-height: normal;
}

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer;
}

button[disabled],
input[disabled] {
  cursor: default;
}

input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

input[type="text"],
input[type="search"],
input[type="password"],
input[type="email"],
input[type="tel"],
textarea,
select{
  font-family: inherit;
  font-weight: inherit;
  -webkit-appearance: none;
  appearance: none;
  border-radius: 0;

  &:active,
  .no_touch &:hover,
  &:focus{
    outline: 0;
  }

  &:focus{
    -webkit-tap-highlight-color:  rgba(255, 255, 255, 1);
    -webkit-touch-callout: default;
    -webkit-user-select: text;
    -khtml-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
    outline: 0 none;
  }
}



input[type="submit"]{
  -webkit-appearance: none;
  appearance: none;
  border-radius: 0;

  &:active,
  .no_touch &:hover,
  &:focus{
    outline: 0;
  }
}

select{
  -webkit-appearance: menulist;
}

input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}
