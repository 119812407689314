.filter {
  @include font;

  .filter-content {
    max-height: 500px;
    overflow-y: auto;
    overflow-x: hidden;
    padding:0 2px 12px 12px;
    @include easeInOut(0.1s);

    &.collapsed {
      height: 0;
      overflow: hidden;
      opacity: 0;
      padding: 0;
    }
  }

  .mat-label {
    @include fontBd;
    text-transform: uppercase;
    cursor: pointer;
    padding:12px;
    display: block;
    border:0 solid $light-grey;
    border-top-width: 2px;
    mat-icon {
      float: right;
      margin-top: -4px;
    }

    &.force-line-break {
      word-spacing: 30px;
    }
  }

  .mat-checkbox-inner-container {
    display: inline-block;
    height: 13px;
    line-height: 0;
    margin: auto;
    margin-right: 8px;
    order: 0;
    position: relative;
    vertical-align: middle;
    white-space: nowrap;
    width: 13px;
    flex-shrink: 0;
  }

  .mat-select-value-text {
    @include fontBd;
  }

  .mat-select-value {
    @include font;
  }
}
