// KLARO DIALOG
.klaro {
  .cookie-modal {
    z-index: 1200;

    h1 {
      font-size: 1.5rem !important;
    }
  }

  .cookie-notice {
    z-index: 9000 !important;
  }
}

html {
  --button-text-color: #fff;
  --dark1: #fafafa;
  --dark2: #777;
  --dark3: #555;
  --light1: #444;
  --light2: #666;
  --light3: #111;
  --green1: #{$primary};
  --blue1: #{$accent};
}
